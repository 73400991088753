import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Double or/& Triple Unders`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`“Brenton”`}</em></strong></p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`100ft Bear Crawl`}</p>
    <p>{`100Ft Broad Jump `}{`*`}{`3 Burpees after every 5 Broad Jumps`}</p>
    <p><em parentName="p">{`*`}{`*`}{`Notate time of “Brenton” and go immediately in to: `}</em></p>
    <p>{`4 Rounds of:`}</p>
    <p>{`40-Double Unders`}</p>
    <p>{`400M Row`}</p>
    <p><em parentName="p">{`Record “Brenton” time and total time.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, February 4th, we will have our 3rd Annual Sports
Nutrition seminar with Dawn from Sports Nutrition 2 Go in Cincinnati.
The seminar will be from 12-2pm. \\$35/person for the seminar. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Subjects covered will include:`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Hydration—How impacts performance, electrolytes, what to drink`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Breakfast—effects afternoon performance by 5% and effects
appetite`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Nutrient Timing—what to eat before/after, when`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Sleep and Nutrition on performance---Discuss percentages on
outcomes`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Discuss different nutrition philosophies`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Supplements and Labs: what to take and which labs to draw`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions to her ahead of time
please send them to: Daniel\\@crossfitlouisvilleeast.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Want to learn the exact number of calories your body burns each
day? Metabolic Testing will be offered before and after the seminar as
well! \\$65 for Metabolic Testing. Please email Daniel if you’d like to
sign up for Metabolic testing either before or after the seminar. There
is a sign up sheet at The Ville by the front door and at East on the
back wall. More time slots can be added earlier in the morning if needed
but slots are filling up fast so sign up today.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      